function SpotifyLinked() {
  const redirectLink = "https://fanhouse.app/app-link";

  return (
    <div className="pay-confirm-modal center">
      <div style={{ height: "10vh" }} />

      {/* Action */}
      <p className="header">Spotify account linked!</p>

      <hr className="divider" />
      <button
        type="button"
        className="button-confirm"
        onClick={() => {
          window.location.href = redirectLink;
        }}
      >
        Return to Fanhouse app
      </button>
    </div>
  );
}

export default SpotifyLinked;
