import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

import "./index.css";

import Pay from "./Pay";
import SpotifyLinked from "./SpotifyLinked";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Pay />} />

      <Route path="spotify-linked" element={<SpotifyLinked />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
