import React, { useState } from "react";

import * as QueryString from "query-string";
import { DotLoader } from "react-spinners";

function Pay() {
  const redirectLink = "https://fanhouse.app/app-link?confirm=";
  const [loading, setLoading] = useState(false);

  const { action, profpic, username, price, interval } = QueryString.parse(
    window.location.search
  );

  const [error] = React.useState(!action || !username || !price);

  if (error) {
    return (
      <div className="center">
        <p className="header">Error: cannot load page.</p>
      </div>
    );
  }
  return (
    <div className="pay-confirm-modal center">
      <div style={{ height: "10vh" }} />

      {/* Action */}
      <p className="header">
        {action === "tip"
          ? "Tipping:"
          : action === "unlock"
          ? "Unlocking from:"
          : "Subscribing to:"}
      </p>

      <img
        className="img-profile"
        src={decodeURI(profpic as string)}
        onError={(ev: any) => {
          ev.target.style.display = "none";
        }}
        alt="img"
      />

      {/* Username */}
      <p className="header">
        <strong>{username}</strong>
      </p>

      <hr className="divider" />
      {/* Descrip (from action) + price */}
      <p className="text-body">
        <span className="left">
          Fanhouse{" "}
          {action === "tip"
            ? "non-refundable tip"
            : action === "unlock"
            ? "locked message"
            : `${interval ?? 1}-month subscription`}{" "}
        </span>
        <span className="right">${price}</span>
      </p>
      <div style={{ height: "25vh" }} />

      {/* Confirm + cancel */}
      {!loading && (
        <>
          <button
            type="button"
            className="button-confirm"
            onClick={async () => {
              setLoading(true);
              await new Promise((resolve) => setTimeout(resolve, 2000));
              window.location.href = redirectLink + "true";
            }}
          >
            Confirm
          </button>

          <button
            type="button"
            className="text-cancel"
            onClick={() => (window.location.href = redirectLink + "false")}
          >
            Cancel
          </button>
        </>
      )}

      {loading && (
        <>
          <p className="text-body">One moment...</p>
          <DotLoader
            css={`
              display: block;
              margin: 0 auto;
            `}
            size={50}
            color={"#000000"}
            loading={loading}
          />{" "}
        </>
      )}
    </div>
  );
}

export default Pay;
